// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-back-index-js": () => import("./../../../src/pages/back-index.js" /* webpackChunkName: "component---src-pages-back-index-js" */),
  "component---src-pages-federal-standard-deduction-tsx": () => import("./../../../src/pages/federal-standard-deduction.tsx" /* webpackChunkName: "component---src-pages-federal-standard-deduction-tsx" */),
  "component---src-pages-federal-tax-forms-tsx": () => import("./../../../src/pages/federal-tax-forms.tsx" /* webpackChunkName: "component---src-pages-federal-tax-forms-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-tax-forms-tsx": () => import("./../../../src/pages/tax-forms.tsx" /* webpackChunkName: "component---src-pages-tax-forms-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-federal-tax-form-tsx": () => import("./../../../src/templates/federal-tax-form.tsx" /* webpackChunkName: "component---src-templates-federal-tax-form-tsx" */),
  "component---src-templates-federal-tsx": () => import("./../../../src/templates/federal.tsx" /* webpackChunkName: "component---src-templates-federal-tsx" */),
  "component---src-templates-state-tax-form-index-tsx": () => import("./../../../src/templates/state-tax-form-index.tsx" /* webpackChunkName: "component---src-templates-state-tax-form-index-tsx" */),
  "component---src-templates-state-tax-form-tsx": () => import("./../../../src/templates/state-tax-form.tsx" /* webpackChunkName: "component---src-templates-state-tax-form-tsx" */),
  "component---src-templates-state-tsx": () => import("./../../../src/templates/state.tsx" /* webpackChunkName: "component---src-templates-state-tsx" */)
}

